import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CachedIcon,
    CircularProgress,
    CustomAlert,
    DatePicker,
    FormControl,
    Grid,
    LoadingButton,
    LocalizationProvider,
    SearchIcon,
    Typography,
    AdapterMoment,
} from "@enerbit/base";
import moment from "moment";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import ChartContainer from "../ChartContainer/ChartContainer";
import { DATE_TIME_FORMAT } from "../../constants/orders";
import { getOrdersByGeo } from "../../services";
import { OrdersByGeo } from "../../models/orders";

import "./OrdersByCity.css";
import "leaflet/dist/leaflet.css";
import CustomPopup from "./CustomPopup";
import OrdersDetail from "./OrdersDetail";
import useMediaQuery from "../../hooks/useMediaQuery";
import { MOBILE_BREAKPOINT } from "../../constants/common";

const DEFAULT_SINCE = moment()
    .subtract(3, "month")
    .startOf("month")
    .format(DATE_TIME_FORMAT);
const DEFAULT_UNTIL = moment().endOf("month").format(DATE_TIME_FORMAT);

const customIcon = new L.Icon({
    iconUrl: require("./assets/placeholder.png"),
    iconSize: [38, 38],
});

const OrdersByCity = () => {
    const [since, setSince] = useState<string>(DEFAULT_SINCE);
    const [until, setUntil] = useState<string>(DEFAULT_UNTIL);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const mapPopsitions: [number, number] = [5.4541666666667, -73.361944444444];
    const [orders, setOrders] = useState<OrdersByGeo[]>([]);

    const isTablet = useMediaQuery(MOBILE_BREAKPOINT);

    const fetchOrders = async (since: string, until: string) => {
        setError(false);
        setLoading(true);
        try {
            const tempOrders = await getOrdersByGeo(since, until);
            setOrders(tempOrders);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };
    const handleRefresh = () => {
        setSince(DEFAULT_SINCE);
        setUntil(DEFAULT_UNTIL);
        fetchOrders(DEFAULT_SINCE, DEFAULT_UNTIL);
    };

    useEffect(() => {
        fetchOrders(DEFAULT_SINCE, DEFAULT_UNTIL);
    }, []);

    return (
        <>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                <Box>
                    <FormControl fullWidth sx={{ mr: 1 }}>
                        <Typography>Desde</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format="MMMM, YYYY"
                                value={moment(since)}
                                onChange={(value: any) => {
                                    setSince(
                                        moment(value).format(DATE_TIME_FORMAT),
                                    );
                                }}
                                views={["year", "month"]}
                                openTo="year"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        sx: {
                                            borderRadius: "12px",
                                            ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "12px",
                                                },
                                            ".MuiInputBase-root": {
                                                height: "41px",
                                            },
                                        },
                                    },
                                }}
                            ></DatePicker>
                        </LocalizationProvider>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth sx={{ mr: 1 }}>
                        <Typography>Hasta</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format="MMMM, YYYY"
                                value={moment(until)}
                                onChange={(value: any) => {
                                    setUntil(
                                        moment(value)
                                            .endOf("month")
                                            .format(DATE_TIME_FORMAT),
                                    );
                                }}
                                views={["year", "month"]}
                                openTo="year"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        sx: {
                                            borderRadius: "12px",
                                            ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "12px",
                                                },
                                            ".MuiInputBase-root": {
                                                height: "41px",
                                            },
                                        },
                                    },
                                }}
                            ></DatePicker>
                        </LocalizationProvider>
                    </FormControl>
                </Box>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => fetchOrders(since, until)}
                    loading={loading}
                    sx={{ mt: 2.7, height: "40px", width: "5%" }}
                >
                    <SearchIcon />
                </LoadingButton>
                <Button
                    onClick={handleRefresh}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2.7, height: "40px", width: "5%" }}
                >
                    <CachedIcon />
                </Button>
            </Box>
            <Grid
                container
                columnSpacing={2}
                rowSpacing={1}
                sx={{
                    display: "flex",
                    flexDirection: isTablet ? "column-reverse" : "row",
                }}
            >
                <Grid item xs={loading || isTablet ? 12 : 8}>
                    <ChartContainer
                        height="500px"
                        sx={{
                            padding: "0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {loading && (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                        {!loading && error && (
                            <CustomAlert
                                severity="error"
                                text="Error cargando la información."
                                onClose={() => setError(false)}
                            />
                        )}
                        {!loading && !error && (
                            <MapContainer
                                className="map"
                                center={mapPopsitions}
                                zoom={6}
                            >
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                <MarkerClusterGroup>
                                    {orders.map((order) => (
                                        <Marker
                                            key={order.id}
                                            position={[
                                                order.geolocalization.latitude,
                                                order.geolocalization.longitude,
                                            ]}
                                            icon={customIcon}
                                        >
                                            <CustomPopup order={order} />
                                        </Marker>
                                    ))}
                                </MarkerClusterGroup>
                            </MapContainer>
                        )}
                    </ChartContainer>
                </Grid>
                {!loading && !error && (
                    <Grid item xs={isTablet ? 12 : 4}>
                        <OrdersDetail orders={orders} />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default OrdersByCity;
