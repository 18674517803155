import { Button, LoadingButton } from "@enerbit/base";
import AssignmentIcon from "@mui/icons-material/Assignment";

interface Props {
    onClick: () => void;
    children: React.ReactNode;
    loading: boolean;
}

const DownloadButton = ({ onClick, children, loading }: Props) => {
    return (
        <LoadingButton
            onClick={onClick}
            variant="contained"
            color="info"
            loading={loading}
            sx={{
                borderRadius: "12px !important",
                height: "32px",
                minWidth: "unset !important",
                display: "flex",
                transform: "none !important",
                width: "48px !important",
                backgroundColor: "#3B82F6",
            }}
        >
            {children}
        </LoadingButton>
    );
};

export default DownloadButton;
