import { styled, enerbitColors, Box } from "@enerbit/base";

interface Props {
    height?: string;
}

const ChartContainer = styled(Box)<Props>(({ height }) => ({
    height: height ?? "220px",
    backgroundColor: enerbitColors.neutral[100],
    borderRadius: "8px",
    padding: "24px",
}));

export default ChartContainer;
