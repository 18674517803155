import { styled } from "@enerbit/base";

const ModalMask = styled("label")`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 1300px;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

export default ModalMask;
