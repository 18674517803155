import moment from "moment";
import {
    AverageHoursViewType,
    OrderCodes,
    OrderGroups,
    Orders,
    OrdersByMeasurementType,
    OrdersByType,
} from "../models/orders";
import { ORDERS_GROUPED_BY_TYPES } from "../constants/orders";
import { Event } from "react-big-calendar";
import ShortUniqueId from "short-unique-id";

export const toCapitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

interface GroupedOrders {
    [month: string]: OrdersByType[];
}

export const formatOrdersByType = (orders: OrdersByType[]) => {
    const ordersByMonth = groupOrdersByMonth(orders);
    const formatedOrders: Record<
        string,
        Record<string, Record<string, number>>
    > = {};

    const detailedData = accumulateOrdersByType(ordersByMonth);

    Object.keys(detailedData).forEach((key) => {
        const orders = detailedData[key];
        const ordersByGroups: Record<OrderGroups, any> = {
            CANCELATION: {},
            INSPECTION: {},
            INSTALLATION: {},
            NORMALIZATION: {},
            SUSPENSION_RECONNECTION: {},
        };

        Object.keys(orders).forEach((orderCode: string) => {
            Object.keys(ORDERS_GROUPED_BY_TYPES).forEach((groupKey) => {
                if (
                    ORDERS_GROUPED_BY_TYPES[groupKey as OrderGroups].includes(
                        orderCode as OrderCodes,
                    )
                ) {
                    ordersByGroups[groupKey as OrderGroups][orderCode] =
                        orders[orderCode];
                }
            });
        });

        formatedOrders[key] = ordersByGroups;
    });

    const totals = getTotal(formatedOrders);

    const sortedKeys = Object.keys(formatedOrders).sort((a, b) =>
        moment(b).isBefore(a) ? 1 : -1,
    );

    const sortedTotals: Record<string, Record<OrderGroups, number>> = {};
    const sortedDetail: Record<string, any> = {};

    sortedKeys.forEach((key) => {
        sortedTotals[key] = totals[key];
    });

    sortedKeys.forEach((key) => {
        sortedDetail[key] = formatedOrders[key];
    });

    return { sortedTotals, sortedDetail };
};

const getTotal = (
    data: Record<string, Record<string, Record<string, number>>>,
) => {
    const totals: Record<string, any> = {};

    Object.keys(data).forEach((monthKey) => {
        const ordersByMonth = data[monthKey];
        Object.keys(ordersByMonth).forEach((groupKey) => {
            const sum = Object.values(data[monthKey][groupKey]).reduce(
                (acc, entry) => acc + entry,
                0,
            );
            if (!Object.keys(totals).includes(monthKey)) {
                totals[monthKey] = {};
            }
            totals[monthKey][groupKey] = sum;
        });
    });

    return totals;
};

const groupOrdersByMonth = (orders: OrdersByType[]): GroupedOrders => {
    const groupedOrders: GroupedOrders = {};

    orders.forEach((order) => {
        const monthKey = moment(order.formatted_date)
            .utcOffset(0)
            .format("YYYY-MM");

        if (!groupedOrders[monthKey]) {
            groupedOrders[monthKey] = [];
        }

        groupedOrders[monthKey].push(order);
    });

    return groupedOrders;
};

export const formatCalendarEvents = (orders: Orders[]) => {
    const events: Event[] = [];
    orders.forEach((order) => {
        events.push({
            allDay: false,
            title: order.order_type ? order.order_type.name : "",
            start: moment(order.planned_date_begin)
                .utcOffset("-05:00")
                .toDate(),
            end: moment(order.planned_date_end).utcOffset("-05:00").toDate(),
            resource: { order },
        });
    });
    return events;
};

export const getEventStyles = (event: Event) => {
    const backgroundColor = "#F2F4F7";
    const fontColor = "#667085";
    const style = {
        backgroundColor,
        borderRadius: "4px",
        border: `1px solid ${fontColor}`,
        color: fontColor,
    };
    return {
        style,
    };
};

export const roundNumber = (num: number) => {
    const decimal = num - Math.floor(num);

    if (decimal >= 0.6) {
        return Math.ceil(num);
    } else {
        return Math.floor(num);
    }
};

function consolidateDataByMonth(
    data: Record<string, OrdersByMeasurementType[]>,
) {
    const consolidatedData: Record<any, any> = {};

    Object.keys(data).forEach((monthKey) => {
        const monthData = data[monthKey];

        const consolidatedMonthData = monthData.reduce(
            (acc, entry) => {
                acc.total_orders += entry.total_orders;
                acc.total_time_directa += entry.total_time_directa;
                acc.total_time_indirecta += entry.total_time_indirecta;
                acc.total_time_semidirecta += entry.total_time_semidirecta;
                acc.total_orders_directa += entry.total_orders_directa;
                acc.total_orders_indirecta += entry.total_orders_indirecta;
                acc.total_orders_semidirecta += entry.total_orders_semidirecta;

                return acc;
            },
            {
                total_orders: 0,
                total_time_directa: 0,
                total_time_indirecta: 0,
                total_time_semidirecta: 0,
                total_orders_directa: 0,
                total_orders_indirecta: 0,
                total_orders_semidirecta: 0,
            },
        );

        consolidatedData[monthKey] = {
            ...consolidatedMonthData,
            formatted_date: monthKey,
        };
    });

    return Object.values(consolidatedData).map((item) => item);
}

function groupByMonth(data: OrdersByMeasurementType[]) {
    const groupedData: Record<any, any> = {};

    data.forEach((item) => {
        const monthKey = moment(item.formatted_date)
            .utcOffset(0)
            .format("YYYY-MM");

        if (!groupedData[monthKey]) {
            groupedData[monthKey] = [];
        }

        groupedData[monthKey].push(item);
    });

    return groupedData;
}

export const formatAverageHoursDatasets = (
    orders: OrdersByMeasurementType[],
    view: AverageHoursViewType,
) => {
    const data: OrdersByMeasurementType[] =
        view === AverageHoursViewType.MONTH
            ? consolidateDataByMonth(groupByMonth(orders))
            : orders;
    const datasets = [
        {
            type: "line" as const,
            label: "Minutos promedio directa",
            data: data.map(() => {
                const totalMinutes = data.reduce(
                    (acc, entry) =>
                        acc + secondsToMinutes(entry.total_time_directa),
                    0,
                );
                const totalOrders = data.reduce(
                    (acc, entry) => acc + entry.total_orders_directa,
                    0,
                );

                return roundNumber(totalMinutes / totalOrders);
            }),
            borderColor: "#53358E",
            fill: false,
            hidden: true,
        },
        {
            type: "line" as const,
            label: "Minutos promedio semidirecta",
            data: data.map(() => {
                const totalMinutes = data.reduce(
                    (acc, entry) =>
                        acc + secondsToMinutes(entry.total_time_semidirecta),
                    0,
                );
                const totalOrders = data.reduce(
                    (acc, entry) => acc + entry.total_orders_semidirecta,
                    0,
                );

                return roundNumber(totalMinutes / totalOrders);
            }),
            borderColor: "#53358E",
            fill: false,
            hidden: true,
        },
        {
            type: "line" as const,
            label: "Minutos promedio indirecta",
            data: data.map(() => {
                const totalMinutes = data.reduce(
                    (acc, entry) =>
                        acc + secondsToMinutes(entry.total_time_indirecta),
                    0,
                );
                const totalOrders = data.reduce(
                    (acc, entry) => acc + entry.total_orders_indirecta,
                    0,
                );

                return roundNumber(totalMinutes / totalOrders);
            }),
            borderColor: "#53358E",
            fill: false,
            hidden: true,
        },
        {
            label: "Directa",
            data: data.map((item) =>
                roundNumber(
                    secondsToMinutes(item.total_time_directa) /
                        item.total_orders_directa,
                ),
            ),
            backgroundColor: "#3B82F6",
            borderRadius: 4,
            type: "bar" as const,
            id: "1",
        },
        {
            label: "Semidirecta",
            data: data.map((item) =>
                roundNumber(
                    secondsToMinutes(item.total_time_semidirecta) /
                        item.total_orders_semidirecta,
                ),
            ),
            backgroundColor: "#27AE60",
            borderRadius: 4,
            type: "bar" as const,
        },
        {
            label: "Indirecta",
            data: data.map((item) =>
                roundNumber(
                    secondsToMinutes(item.total_time_indirecta) /
                        item.total_orders_indirecta,
                ),
            ),
            backgroundColor: "#F29139",
            borderRadius: 4,
            type: "bar" as const,
        },
    ];

    const labels = data.map((item) =>
        moment(item.formatted_date)
            .utcOffset(0)
            .format(view === AverageHoursViewType.DAY ? "DD" : "MMMM YYYY"),
    );
    return { datasets, labels, data };
};

export const sortMonths = (a: any, b: any) => {
    var months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    const itemA = toCapitalize(moment(a).format("MMMM"));
    const itemB = toCapitalize(moment(b).format("MMMM"));
    return months.indexOf(itemA) - months.indexOf(itemB);
};

export const getMaxTime = (data: OrdersByMeasurementType[]) => {
    const times = data.map((item) => {
        const values: number[] = [];
        if (item.total_orders_directa > 0) {
            values.push(
                secondsToMinutes(item.total_time_directa) /
                    item.total_time_directa,
            );
        }
        if (item.total_orders_semidirecta > 0) {
            values.push(
                secondsToMinutes(item.total_time_semidirecta) /
                    item.total_time_semidirecta,
            );
        }
        if (item.total_orders_indirecta > 0) {
            values.push(
                secondsToMinutes(item.total_time_indirecta) /
                    item.total_time_indirecta,
            );
        }
        return values;
    });

    const max = Math.max(...times.flat());

    return max;
};

export const getMinTime = (data: OrdersByMeasurementType[]) => {
    const times = data.map((item) => {
        const values: number[] = [];
        if (item.total_time_directa > 0) {
            values.push(item.total_time_directa);
        }
        if (item.total_time_semidirecta > 0) {
            values.push(item.total_time_semidirecta);
        }
        if (item.total_time_indirecta > 0) {
            values.push(item.total_time_indirecta);
        }
        return values;
    });

    const min = Math.min(...times.flat());

    return min;
};

export const secondsToMinutes = (sec: number) => {
    return sec / 60;
};

export function accumulateOrdersByType(data: GroupedOrders) {
    const resultado: Record<string, any> = {};

    for (const mes in data) {
        if (data.hasOwnProperty(mes)) {
            resultado[mes] = data[mes].reduce((acc, orden) => {
                const orderCode = orden.order_code;
                const totalOrders = orden.total_orders;

                acc[orderCode] = (acc[orderCode] || 0) + totalOrders;
                return acc;
            }, {} as any);
        }
    }

    return resultado;
}

export function sortObjectByValue(obj: any) {
    const entries = Object.entries(obj);

    entries.sort((a: any, b: any) => b[1] - a[1]);

    const sortedObject = Object.fromEntries(entries);

    return sortedObject;
}

export const generateRandomId = () => {
    const { randomUUID } = new ShortUniqueId({ length: 10 });

    return randomUUID();
};
