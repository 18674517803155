import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Modal,
    SxProps,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { Event } from "react-big-calendar";
import * as singleSpa from "single-spa";
import {
    OperatorDetail,
    OrderEventDetail,
    OrderStatus,
} from "../../../models/orders";
import {
    authPicture,
    getOperatorDetail,
    getOrderClientData,
    getOrderHistorical,
} from "../../../services";
import ModalMask from "../../ModalMask/ModalMask";
import ClientsData from "./ClientsData";
import GeneralInformation from "./GeneralInformation";
import ModalHeader from "./ModalHeader";
import Operators from "./Operators";
import OrdersForm from "./OrdersForm";
import "../../../styles/index.css";

interface Props {
    event: Event;
    open: boolean;
    handleClose: () => void;
}

const style: SxProps = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
    width: "600px",
    height: "500px",
    overflow: "auto",
};

const EventModal = ({ event, handleClose, open }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [details, setDetails] = useState<OrderEventDetail>();

    const getDetails = async () => {
        setError(false);
        setLoading(true);
        try {
            let operatorDetail = {} as OperatorDetail;
            const createdBy = await getOrderHistorical(event.resource.order.id);
            const clients =
                event.resource.order.order_entities.length > 0
                    ? await getOrderClientData(
                          event.resource.order.order_entities,
                      )
                    : [];
            let pictureUrl = undefined;

            if (event.resource.order.status === OrderStatus.CANCEL) {
                operatorDetail = await getOperatorDetail(
                    event.resource.order.order_cancel.cancel_by,
                );
                pictureUrl = event.resource.order.order_cancel.picture_url
                    ? await authPicture(
                          event.resource.order.order_cancel.picture_url,
                      )
                    : undefined;
            } else if (event.resource.order.status === OrderStatus.DONE) {
                operatorDetail = await getOperatorDetail(
                    event.resource.order.order_done.done_by,
                );
            }

            setDetails({
                operator: operatorDetail,
                picture: pictureUrl,
                responsible: createdBy[0].responsible,
                entitie: clients,
            });
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <ModalHeader event={event} handleClose={handleClose} />
                {loading && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 2,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {error && !loading && (
                    <Box my={2}>
                        <CustomAlert
                            severity="error"
                            text="Error consultando detalles de la orden"
                            onClose={() => setError(false)}
                        />
                    </Box>
                )}
                {!loading && !error && details ? (
                    <>
                        <GeneralInformation details={details} event={event} />
                        <ClientsData event={event} details={details} />
                        <Operators event={event} open={open} />
                        <OrdersForm details={details} event={event} />
                        <Button
                            onClick={() =>
                                window.open(
                                    `/#/orders/${event.resource.order.id}`,
                                    "_blank",
                                )
                            }
                            variant="outlined"
                            color="secondary"
                            sx={{ my: 1 }}
                        >
                            Ver detalles de la orden
                        </Button>
                    </>
                ) : null}
            </Box>
        </Modal>
    );
};

export default EventModal;
