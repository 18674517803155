import EventSource from "react-native-sse";
import { getAuthInfo, refreshToken } from "./token";

import type { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { EventSourceListener } from "react-native-sse";
import { Orders } from "../models/orders";
import { OrderParams, SSEEvent } from "../models/calendar";
import {
    addOrders,
    setError,
    setLoading,
    updateMothsFetched,
} from "../store/slices/calendar";

const baseUrl = process.env.REACT_APP_BASE_URL;

let es: EventSource<string>;

export const initializeEventSource = async (
    token: string,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    params: OrderParams,
    date: string,
    isNavigate: boolean | undefined,
    isClose = false,
) => {
    const url = new URL("/service-orders/sse/orders", baseUrl);
    Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key]),
    );

    const connectEventSource = (token: string) => {
        es = new EventSource(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    connectEventSource(token);

    let items = [] as Orders[];

    const itemsPromise = new Promise<Orders[]>((resolve, reject) => {
        const listener = async (event: SSEEvent) => {
            if (isClose) {
                return () => {
                    es.removeEventListener(
                        "orders",
                        listener as EventSourceListener<"orders">,
                    );
                    es.removeEventListener("end", listener);
                    es.removeEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                    es.close();
                };
            }
            if (event.type === "orders") {
                const data = JSON.parse(event.data);
                items = [...items, data.data];
                dispatch(addOrders(data.data));
            } else if (event.type === "error") {
                if (event.xhrStatus === 401) {
                    es.removeEventListener(
                        "orders",
                        listener as EventSourceListener<"orders">,
                    );
                    es.removeEventListener("end", listener);
                    es.removeEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                    es.close();
                    const newToken = await refreshToken();
                    const authInfo = {
                        ...getAuthInfo(),
                        access_token: newToken,
                    };
                    localStorage.setItem("authInfo", JSON.stringify(authInfo));
                    connectEventSource(newToken!);
                    // Re-add the event listeners after reconnecting
                    es.addEventListener(
                        "orders",
                        listener as EventSourceListener<"orders">,
                    );
                    es.addEventListener("end", listener);
                    es.addEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                } else {
                    dispatch(setError("Error cargando órdenes."));
                    dispatch(setLoading(true));
                    reject(event.message);
                }
            } else if (event.type === "exception") {
                reject(event.message);
                es.removeEventListener(
                    "orders",
                    listener as EventSourceListener<"orders">,
                );
                es.removeEventListener("end", listener);
                es.removeEventListener(
                    "error",
                    listener as EventSourceListener<"error">,
                );
                es.close();
            } else if (event.type === "end") {
                es.removeEventListener(
                    "orders",
                    listener as EventSourceListener<"orders">,
                );
                es.removeEventListener("end", listener);
                es.removeEventListener(
                    "error",
                    listener as EventSourceListener<"error">,
                );
                es.close();

                dispatch(setError(undefined));
                dispatch(setLoading(false));
                resolve(items);
            }
        };

        es.addEventListener(
            "orders",
            listener as EventSourceListener<"orders">,
        );
        es.addEventListener("end", listener);
        es.addEventListener("error", listener as EventSourceListener<"error">);
    });

    return await itemsPromise;
};
