import React from "react";
import { Box, Typography, enerbitColors, styled } from "@enerbit/base";
import { Popup } from "react-leaflet";
import { CALENDAR_EVENTS_COLORS } from "../../constants/orders";
import { OrderStatus, OrdersByGeo } from "../../models/orders";

interface Props {
    order: OrdersByGeo;
}

const CustomPopup = ({ order }: Props) => {
    return (
        <Popup minWidth={420} className="leaflet-custom-popup">
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box>
                    <Typography sx={{ color: enerbitColors.neutral.main }}>
                        ID
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {order.id}
                    </Typography>
                </Box>
                <Typography sx={{ color: enerbitColors.neutral.main }}>
                    Tipo de servicio: <strong>{order.order_type_name}</strong>
                </Typography>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography sx={{ color: enerbitColors.neutral.main }}>
                        Estado:
                    </Typography>
                    <Tag
                        bgcolor={
                            CALENDAR_EVENTS_COLORS[order.status as OrderStatus]
                                .bgcolor
                        }
                    >
                        {
                            CALENDAR_EVENTS_COLORS[order.status as OrderStatus]
                                .label
                        }
                    </Tag>
                </Box>
            </Box>
        </Popup>
    );
};

export default CustomPopup;

type TagProps = { bgcolor: string };

const Tag = styled(Box)<TagProps>(({ bgcolor }) => ({
    borderRadius: "4px",
    color: "#fff",
    padding: "1px 5px",
    backgroundColor: bgcolor,
    maxWidth: "200px",
    textAlign: "center",
    fontWeight: 700,
}));
