import { Box, Grid, Typography } from "@enerbit/base";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import OrderProgress from "../../../components/OrdersProgress/OrderProgress";
import OrdersByMeasurementType from "../../../components/OrdersByMeasurementType/OrdersByMeasurementType";

const TodayOverview = () => {
    return (
        <Box>
            <Typography
                color="primary"
                sx={{ fontWeight: 700, fontSize: "22px", mb: 1 }}
            >
                ¿Cómo vamos hoy?
            </Typography>
            <Grid container columnSpacing={1} rowSpacing={2}>
                <Grid item md={4} xs={12}>
                    <OrderProgress />
                </Grid>
                <Grid item md={8} xs={12}>
                    <OrdersByMeasurementType />
                </Grid>
            </Grid>
        </Box>
    );
};

export default TodayOverview;
