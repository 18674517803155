import { Box } from "@enerbit/base";
import TodayOverview from "./sections/TodayOverview";
import ExecutedOrders from "./sections/ExecutedOrders";
import SpecificSearch from "./sections/SpecificSearch";
import AssignedOrders from "./sections/AssignedOrders";
import TotalClients from "./sections/TotalClients";

const Overview = () => {
    return (
        <Box>
            <Box>
                <TodayOverview />
            </Box>
            <Box mt={3}>
                <ExecutedOrders />
            </Box>
            <Box my={2}>
                <AssignedOrders />
            </Box>
        </Box>
    );
};

export default Overview;
