import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CalendarInitialState } from "../../models/calendar";
import { LOCAL_MONTHS_FETCHED_KEY } from "../../constants/common";
import { Event } from "react-big-calendar";
import { getOrders, getStatesList } from "../thunks/calendar";
import moment from "moment";
import { Orders } from "../../models/orders";

const initialState: CalendarInitialState = {
    loading: false,
    error: "",
    statesList: [],
    events: [],
    monthsFetched: JSON.parse(
        localStorage.getItem(LOCAL_MONTHS_FETCHED_KEY) || "[]",
    ),
    orders: [],
};

export const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {
        resetMonthsFetched: (state) => {
            localStorage.setItem(LOCAL_MONTHS_FETCHED_KEY, JSON.stringify([]));
            state.monthsFetched = [];
        },
        updateMothsFetched: (state, { payload }) => {
            localStorage.setItem(
                LOCAL_MONTHS_FETCHED_KEY,
                JSON.stringify([
                    ...state.monthsFetched,
                    moment(payload).format("MM-YYYY"),
                ]),
            );
            state.monthsFetched = JSON.parse(
                localStorage.getItem(LOCAL_MONTHS_FETCHED_KEY) || "[]",
            );
        },
        setEvents: (state, { payload }: PayloadAction<Event[]>) => {
            state.events = payload;
        },
        setError: (state, { payload }: PayloadAction<string | undefined>) => {
            state.error = payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        addOrders: (state, { payload }: PayloadAction<Orders>) => {
            state.orders.push(payload);
        },
        resetOrders: (state) => {
            state.orders = [];
        },
    },

    extraReducers(builder) {
        builder.addCase(getStatesList.pending, (state) => {
            state.statesList = [];
        });
        builder.addCase(getStatesList.fulfilled, (state, { payload }) => {
            state.statesList = payload;
        });
        builder.addCase(getStatesList.rejected, (state) => {
            state.statesList = [];
        });
        builder.addCase(getOrders.pending, (state) => {});
        builder.addCase(getOrders.fulfilled, (state, { payload }) => {});
        builder.addCase(getOrders.rejected, (state) => {});
    },
});

export const {
    resetMonthsFetched,
    setEvents,
    updateMothsFetched,
    setError,
    setLoading,
    addOrders,
    resetOrders,
} = calendarSlice.actions;
