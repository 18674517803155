import {
    Box,
    CircularProgress,
    CustomAlert,
    Grid,
    Typography,
    api,
    styled,
} from "@enerbit/base";
import React, { useEffect, useState } from "react";
import { OrderOperators } from "../../../models/orders";
import { Event } from "react-big-calendar";
import { setLoading } from "../../../store/slices/calendar";
import { WorkGroups } from "../../../models/work-groups";

interface Props {
    event: Event;
    open: boolean;
}

const Operators = ({ event, open }: Props) => {
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [workGroups, setWorkGroups] = useState<WorkGroups | null>(null);

    const getWorkGroup = async (id: string) => {
        setError(false);
        setLoading(true);
        try {
            const res = await api.get<WorkGroups>(
                `/work-groups/work-groups/${id}`,
            );
            setWorkGroups(res.data);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open && event.resource.order.work_group_id) {
            getWorkGroup(event.resource.order.work_group_id);
        }

        if (!open) {
            setLoading(false);
            setError(false);
            setWorkGroups(null);
        }
    }, [open]);

    return (
        <Box my={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography color="primary" sx={{ fontSize: "18px" }}>
                Operadores
            </Typography>
            {error && !loading && (
                <Box sx={{ my: 1 }}>
                    <CustomAlert
                        severity="error"
                        text="Error cargando la cuadrilla"
                        onClose={() => setError(false)}
                    />
                </Box>
            )}
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {event.resource.order.order_operators && (
                <OperatorsContainer>
                    <Typography
                        sx={{
                            color: "#050505",
                            fontSize: "16px",
                            mb: 1,
                            fontWeight: 700,
                        }}
                    >
                        {
                            event.resource.order.order_operators[0].pii.company
                                .name
                        }
                    </Typography>

                    <Grid container columnSpacing={1} rowSpacing={2}>
                        {event.resource.order.order_operators.map(
                            (operator: OrderOperators) => (
                                <Grid item xs={6} key={operator.operator_id}>
                                    <Typography
                                        sx={{
                                            color: "#667085",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {operator.pii.full_name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#667085",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {operator.pii.email}
                                    </Typography>
                                </Grid>
                            ),
                        )}
                    </Grid>
                </OperatorsContainer>
            )}
            {event.resource.order.work_group_id &&
                workGroups &&
                !loading &&
                !error && (
                    <OperatorsContainer>
                        <Typography
                            sx={{
                                color: "#050505",
                                fontSize: "16px",
                                mb: 1,
                                fontWeight: 700,
                            }}
                        >
                            {workGroups.operators[0].company.name}
                        </Typography>

                        <Grid container columnSpacing={1} rowSpacing={2}>
                            {workGroups.operators.map((operator) => (
                                <Grid item xs={6} key={operator.id}>
                                    <Typography
                                        sx={{
                                            color: "#667085",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {operator.pii.full_name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#667085",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {operator.username}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </OperatorsContainer>
                )}
        </Box>
    );
};

export default Operators;

const OperatorsContainer = styled(Box)(() => ({
    width: "100%",
    borderRadius: "12px",
    backgroundColor: "#F2F4F7",
    padding: "12px",
}));
