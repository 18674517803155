import { Box, SxProps, Typography } from "@enerbit/base";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CodeIcon from "@mui/icons-material/Code";
import DescriptionIcon from "@mui/icons-material/Description";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import { Event } from "react-big-calendar";
import { ORDER_ENTITIES_NAMES } from "../../../constants/orders";
import { OrderEntityCode, OrderEventDetail } from "../../../models/orders";

const dataStyles: SxProps = {
    color: "#667085",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    gap: 1,
};

interface Props {
    event: Event;
    details: OrderEventDetail;
}

const GeneralInformation = ({ event, details }: Props) => {
    return (
        <Box my={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={dataStyles}>
                <DescriptionIcon />
                <Typography>Entidad: </Typography>
                {event.resource.order.order_entities.length > 0
                    ? ORDER_ENTITIES_NAMES[
                          event.resource.order.order_entities[0].entity
                              .code as OrderEntityCode
                      ]
                    : "Sin entidad"}
            </Typography>
            <Typography sx={dataStyles}>
                <CodeIcon />
                <Typography>Id: </Typography>
                {event.resource.order.id}
            </Typography>
            <Typography sx={dataStyles}>
                <PersonIcon />
                <Typography>Creada por: </Typography>
                {details.responsible.names}
            </Typography>
            <Typography sx={dataStyles}>
                <AccessTimeIcon />
                <Typography>Hora programada: </Typography>
                {`${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`}
            </Typography>
            <Typography sx={dataStyles}>
                <LocationOnIcon />
                {`${event.resource.order.city} - ${event.resource.order.state}`}
            </Typography>
        </Box>
    );
};

export default GeneralInformation;
