import {
    AdapterMoment,
    Box,
    Button,
    DatePicker,
    FilterListIcon,
    FormControl,
    FormControlLabel,
    FormLabel,
    KeyboardArrowDownIcon,
    LocalizationProvider,
    Menu,
    MenuProps,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    styled,
} from "@enerbit/base";
import React, { useState } from "react";
import {
    AVERAGE_HOURS_VIEW_FILTERS,
    DATE_TIME_FORMAT,
} from "../../constants/orders";
import { AverageHoursViewType } from "../../models/orders";
import moment from "moment";
import { es } from "date-fns/locale";

interface Props {
    viewType: AverageHoursViewType;
    setViewType: React.Dispatch<React.SetStateAction<AverageHoursViewType>>;
    fetchOrderHours: (since: string, until: string) => Promise<void>;
    setSince: React.Dispatch<React.SetStateAction<string>>;
    setUntil: React.Dispatch<React.SetStateAction<string>>;
    since: string;
    until: string;
}

const MAX_DAYS = 15;
const MAX_MONTHS = 12;
const MAX_DATE = moment().toDate();
const MIN_DATE = moment("2023-05-01").toDate();

const Filter = ({
    viewType,
    setViewType,
    fetchOrderHours,
    since,
    until,
    setSince,
    setUntil,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleViewTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSince("");
        setUntil("");
        setViewType(event.target.value as AverageHoursViewType);
    };

    const handleSearch = () => {
        setError(undefined);
        if (!since || !until) {
            setError("Selecciona un rango de fechas.");
            return;
        }
        if (
            viewType === AverageHoursViewType.DAY &&
            moment(until).diff(moment(since), "days") + 1 > MAX_DAYS
        ) {
            setError("Puedes consultar máximo 15 días.");
            return;
        }
        if (
            viewType === AverageHoursViewType.MONTH &&
            moment(until).diff(moment(since), "month") > MAX_MONTHS
        ) {
            setError("Puedes consultar máximo 12 meses.");
            return;
        }
        if (moment(since).isAfter(until)) {
            setError("La fecha inicial no puede ser mayor que la fecha final.");
            return;
        }

        if (moment(until).isBefore(since)) {
            setError("La fecha final no puede ser menor que la inicial.");
            return;
        }

        setAnchorEl(null);
        fetchOrderHours(since, until);
    };

    return (
        <Box>
            <Button
                onClick={handleOpenMenu}
                sx={{
                    minHeight: "unset",
                    borderRadius: "12px",
                    border: "1px solid #A3A9B6",
                    height: "30px",
                    display: "felx",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <FilterListIcon sx={{ fontSize: "14px", color: "#667085" }} />
                <Typography sx={{ fontSize: "12px", color: "#14161B" }}>
                    Filtrar
                </Typography>
                <KeyboardArrowDownIcon sx={{ color: "#667085" }} />
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <FormControl>
                    <FormLabel>Tipo de búsqueda</FormLabel>
                    <RadioGroup
                        value={viewType}
                        onChange={handleViewTypeChange}
                    >
                        {AVERAGE_HOURS_VIEW_FILTERS.map((item, index) => (
                            <FormControlLabel
                                key={`${index + 1}-${item.type}`}
                                value={item.type}
                                control={<Radio />}
                                label={item.label}
                            />
                        ))}
                    </RadioGroup>
                    <FormLabel sx={{ my: 1 }}>Rango de fechas</FormLabel>
                    <FormControl sx={{ mb: 1.5 }}>
                        <Typography>Desde</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format={
                                    viewType === AverageHoursViewType.DAY
                                        ? "YYYY-MM-DD"
                                        : "MMMM, YYYY"
                                }
                                views={
                                    viewType === AverageHoursViewType.MONTH
                                        ? ["year", "month"]
                                        : undefined
                                }
                                value={moment(since)}
                                // minDate={MIN_DATE} // Establece la fecha mínima al 1 de julio de 2023
                                // maxDate={MAX_DATE} // Establece la fecha máxima al momento actual
                                onChange={(value: any) => {
                                    setSince(
                                        moment(value).format(DATE_TIME_FORMAT),
                                    );
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        sx: {
                                            borderRadius: "12px",
                                            ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "12px",
                                                },
                                            ".MuiInputBase-root": {
                                                height: "41px",
                                            },
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl>
                        <Typography>Hasta</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format={
                                    viewType === AverageHoursViewType.DAY
                                        ? "YYYY-MM-DD"
                                        : "MMMM, YYYY"
                                }
                                views={
                                    viewType === AverageHoursViewType.MONTH
                                        ? ["year", "month"]
                                        : undefined
                                }
                                value={moment(until)}
                                // minDate={MIN_DATE} // Establece la fecha mínima al 1 de julio de 2023
                                // maxDate={MAX_DATE} // Establece la fecha máxima al momento actual
                                onChange={(value: any) => {
                                    setUntil(
                                        moment(value)
                                            .endOf(
                                                viewType ===
                                                    AverageHoursViewType.DAY
                                                    ? "day"
                                                    : "month",
                                            )
                                            .format(DATE_TIME_FORMAT),
                                    );
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        sx: {
                                            borderRadius: "12px",
                                            ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "12px",
                                                },
                                            ".MuiInputBase-root": {
                                                height: "41px",
                                            },
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    {error ? (
                        <Typography
                            color="error"
                            sx={{ fontSize: "12px", mt: 1 }}
                        >
                            {error}
                        </Typography>
                    ) : null}
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1.5 }}
                        onClick={handleSearch}
                        fullWidth
                    >
                        Buscar
                    </Button>
                </FormControl>
            </StyledMenu>
        </Box>
    );
};

export default Filter;

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
    ({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: "16px",
            width: 250,
            padding: "24px",
            boxShadow:
                "0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important",
        },
    }),
);
