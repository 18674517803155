import { styled, TextField } from "@enerbit/base";

const CustomTextField = styled(TextField)`
  border-radius: 12px;
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
`;

export default CustomTextField;
