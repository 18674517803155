import { SyntheticEvent, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    SxProps,
    Typography,
} from "@enerbit/base";
import AccordionButton from "./AccordionButton";
import { OrdersByMeasurementTypeDetail } from "../../models/orders";
import moment from "moment";
import DownloadButton from "./DownloadButton";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { authCertificate } from "../../services";
import CertificateItem from "./CertificateItem";
import { roundNumber } from "../../helpers";

interface Props {
    index: number;
    order: OrdersByMeasurementTypeDetail;
}

const OrderAccordion = ({ index, order }: Props) => {
    const [expanded, setExpanded] = useState<number | null>(null);
    const [loadingAuthUrl, setLoadingAuthUrl] = useState<boolean>(false);

    const handleChange =
        (index: number, isCabinet: boolean) =>
        (_: SyntheticEvent, isExpanded: boolean) => {
            if (isCabinet) {
                setExpanded(isExpanded ? index : null);
            } else {
                setExpanded(null);
            }
        };

    const accordionSummaryStyles: SxProps = {
        borderRadius: expanded === index ? "12px 12px 0 0" : "12px",
        height: "auto !important",
        border: "1px solid #A3A9B6",
        minHeight: "49px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
            transform: "none !important",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const accordionDetailsStyles: SxProps = {
        p: 2,
        border: expanded === index ? `1px solid #A3A9B6` : null,
        borderRadius: "0 0 12px 12px",
    };

    const calculateTimeDiff = (start: string, end: string) => {
        const startDate = moment(start).utcOffset("-05:00");
        const endDate = moment(end).utcOffset("-05:00");

        return endDate.diff(startDate, "minutes");
    };

    const goToCertificate = async (url: string) => {
        setLoadingAuthUrl(true);
        try {
            const authUrl = await authCertificate(url);
            window.open(authUrl, "_blank");
        } catch (error) {
        } finally {
            setLoadingAuthUrl(false);
        }
    };

    return (
        <>
            <Accordion
                expanded={expanded === index}
                onChange={handleChange(index, order.isCabinet)}
                sx={{
                    my: 2,
                    boxShadow: "unset !important",
                    borderRadius: "12px !important",
                    "&:before": {
                        backgroundColor: "unset !important",
                    },
                }}
            >
                <AccordionSummary
                    sx={accordionSummaryStyles}
                    expandIcon={
                        order.isCabinet ? (
                            <AccordionButton expanded={expanded === index} />
                        ) : (
                            <DownloadButton
                                onClick={() =>
                                    goToCertificate(order.certificates[0].url)
                                }
                                loading={loadingAuthUrl}
                            >
                                <AssignmentIcon sx={{ color: "white" }} />
                            </DownloadButton>
                        )
                    }
                >
                    <Grid container>
                        <Grid
                            item
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            {order.name}
                        </Grid>
                        <Grid item md={6}>
                            <Typography sx={{ fontWeight: 700 }}>
                                {calculateTimeDiff(
                                    order.startDate,
                                    order.endDate,
                                )}{" "}
                                min
                            </Typography>
                            {order.isCabinet && (
                                <Typography sx={{}}>
                                    (
                                    {roundNumber(
                                        calculateTimeDiff(
                                            order.startDate,
                                            order.endDate,
                                        ) / order.certificates.length,
                                    )}{" "}
                                    min promedio)
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsStyles}>
                    {order.certificates.map((certificate) => (
                        <CertificateItem certificate={certificate} />
                    ))}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default OrderAccordion;
