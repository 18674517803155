import { Box, Grid, Typography } from "@enerbit/base";
import OrdersByType from "../../../components/OrdersByType/OrdersByType";
import OrdersByCity from "../../../components/OrdersByCity/OrdersByCity";

const ExecutedOrders = () => {
    return (
        <Box>
            <Typography
                color="primary"
                sx={{ fontWeight: 700, fontSize: "22px" }}
            >
                Órdenes ejecutadas
            </Typography>
            <Grid container columnSpacing={1} rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography color="primary" sx={{ fontSize: "22px" }}>
                        Por tipo
                    </Typography>
                    <Box mt={1}>
                        <OrdersByType />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="primary" sx={{ fontSize: "22px" }}>
                        Por región
                    </Typography>
                    <Box mt={1}>
                        <OrdersByCity />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ExecutedOrders;
