import { Box, Typography, enerbitColors, styled } from "@enerbit/base";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DownloadButton from "./DownloadButton";
import { useState } from "react";
import { authCertificate } from "../../services";

interface Props {
    certificate: {
        url: string;
        measurementType: string;
        id: string;
        meterSerial: string;
    };
}

const CertificateItem = ({ certificate }: Props) => {
    const [loadingAuthUrl, setLoadingAuthUrl] = useState<boolean>(false);

    const goToCertificate = async (url: string) => {
        setLoadingAuthUrl(true);
        try {
            const authUrl = await authCertificate(url);
            window.open(authUrl, "_blank");
        } catch (_error) {
        } finally {
            setLoadingAuthUrl(false);
        }
    };

    return (
        <Box
            key={certificate.id}
            sx={{
                backgroundColor: enerbitColors.neutral[100],
                padding: "8px 12px 8px 12px",
                borderRadius: "12px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box sx={{ display: "flex", gap: 1 }}>
                <PDFIcon>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "10px",
                            color: "white",
                        }}
                    >
                        PDF
                    </Typography>
                </PDFIcon>
                <Box>
                    <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                        ID {certificate.id}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "12px",
                            color: enerbitColors.neutral.main,
                        }}
                    >
                        Medidor: {certificate.meterSerial}
                    </Typography>
                </Box>
            </Box>
            <DownloadButton
                loading={loadingAuthUrl}
                onClick={() => goToCertificate(certificate.url)}
            >
                <OpenInNewIcon />
            </DownloadButton>
        </Box>
    );
};

export default CertificateItem;

const PDFIcon = styled(Box)(() => ({
    width: "40px",
    height: "45px",
    backgroundColor: enerbitColors.error.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
}));
