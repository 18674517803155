import { Box, Grid, Typography, enerbitColors } from "@enerbit/base";
import { OrdersByGeo } from "../../models/orders";
import useMediaQuery from "../../hooks/useMediaQuery";
import { MOBILE_BREAKPOINT } from "../../constants/common";

interface Props {
    orders: OrdersByGeo[];
}

const OrdersDetail = ({ orders }: Props) => {
    const groupedOrders: Record<
        string,
        { total: number; orders: OrdersByGeo[] }
    > = {};
    orders.forEach((order) => {
        if (!groupedOrders[order.state]) {
            groupedOrders[order.state] = { total: 1, orders: [order] };
        } else {
            groupedOrders[order.state].total += 1;
            groupedOrders[order.state].orders.push(order);
        }
    });

    const isTablet = useMediaQuery(MOBILE_BREAKPOINT);

    return (
        <Box
            sx={{
                borderRadius: "16px",
                padding: "16px 24px 16px 24px",
                border: `1px solid ${enerbitColors.neutral.main}`,
                height: "100%",
            }}
        >
            <Typography
                color={"primary"}
                sx={{ fontWeight: 700, fontSize: "20px" }}
            >
                Órdenes de servicio
            </Typography>
            <Typography sx={{ color: enerbitColors.neutral.main }}>
                Cantidad de órdenes por departamento
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} my={2}>
                {Object.keys(groupedOrders)
                    .sort(
                        (a, b) =>
                            groupedOrders[b].total - groupedOrders[a].total,
                    )
                    .map((key) => (
                        <Grid key={key} xs={isTablet ? 4 : 12} item>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: isTablet
                                        ? "normal"
                                        : "space-between",
                                    color: enerbitColors.neutral[700],
                                    fontSize: "16px",
                                    mb: 1,
                                    gap: isTablet ? 1 : 0,
                                }}
                            >
                                <Typography sx={{ fontWeight: 700 }}>
                                    {key}
                                    {isTablet && ":"}
                                </Typography>
                                <Typography sx={{ fontSize: "16px" }}>
                                    {groupedOrders[key].total}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default OrdersDetail;
